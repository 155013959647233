import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { associateEvent, deleteAlbum, load, loadSingleAlbum, updateAlbum } from './actions';
const adapter = createEntityAdapter();
export const albumSlice = createSlice({
    name: 'albums',
    initialState: adapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, { payload }) => {
            adapter.addMany(state, payload.items);
        });
        builder.addCase(loadSingleAlbum.fulfilled, (state, { payload }) => {
            adapter.setOne(state, payload);
        });
        builder.addCase(updateAlbum.fulfilled, (state, { payload }) => {
            adapter.updateOne(state, payload);
        });
        builder.addCase(deleteAlbum.fulfilled, (state, { payload }) => {
            adapter.removeOne(state, payload);
        });
        builder.addCase(associateEvent.fulfilled, (state, { payload }) => {
            adapter.updateOne(state, {
                id: payload.albumId,
                changes: {
                    eventId: payload.eventId,
                },
            });
        });
    },
});
export const albumSelector = adapter.getSelectors((s) => s.albums);
