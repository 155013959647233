import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { Provider } from 'react-redux';
import { history, store } from '~src/state';
import { ModalsProvider } from '~src/components/Modal';
import { Popups } from './Popups';
import { ConnectedRouter } from 'connected-react-router';
import GrapqlProvider from './Graphql';
// import NextAuthProvider from './NextAuthProvider';
const Providers = ({ children }) => {
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsxs(GrapqlProvider, { children: [_jsx(Popups, {}, void 0), _jsx(ConnectedRouter, Object.assign({ history: history }, { children: _jsx(ModalsProvider, { children: _jsx(_Fragment, { children: children }, void 0) }, void 0) }), void 0)] }, void 0) }), void 0));
};
export default Providers;
