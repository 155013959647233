import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import { gql, useQuery } from 'urql';
import { BreadcrumbItem } from '~src/components/Breadcrumb';
import { Button } from '~src/components/Layout';
import Loading from '~src/components/Layout/Loading';
import PageHeader from '~src/components/Layout/PageHeader';
import { useModal } from '~src/components/Modal';
import { useQueryParam } from '~src/hooks/useQuery';
import { ModalAddNewUser } from './ModalAddNewUser';
import UsersList from './UsersList';
export const QUERY_USER_LIST = gql `
  query ($skip: Int!, $take: Int!) {
    users(skip: $skip, take: $take) {
      id
      email
      name
      emailVerified
      accounts {
        type
        provider
        providerAccountId
      }
    }
  }
`;
const Users = () => {
    const [addUser] = useModal(_jsx(ModalAddNewUser, {}, void 0));
    const page = useQueryParam('page', '1');
    const [{ fetching, data }] = useQuery({
        query: QUERY_USER_LIST,
        variables: {
            skip: (+page - 1) * 10,
            take: 10,
        },
    });
    const actions = (_jsx(_Fragment, { children: _jsxs(Button, Object.assign({ variant: "primary", size: "sm", outline: true, onClick: addUser }, { children: [_jsx("i", { className: "fa fa-plus" }, void 0), "\u00A0Add"] }), void 0) }, void 0));
    const users = (data === null || data === void 0 ? void 0 : data.users) || [];
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbItem, { path: "/users", label: "Users" }, void 0), _jsx(PageHeader, Object.assign({ action: actions }, { children: _jsx("h2", { children: "Users" }, void 0) }), void 0), fetching ? _jsx(Loading, { height: "128px" }, void 0) : _jsx(UsersList, { users: users }, void 0)] }, void 0));
};
export default Users;
