import styled from 'styled-components';
export const ImageContainer = styled.div `
  position: relative;
  background-image: url('${(p) => p.src}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: ${(p) => p.borderRadius};
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: ${(p) => p.ratio * 100}%;
  }
`;
