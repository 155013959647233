var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { useCallback } from 'react';
import { useAccessToken } from '~src/state/application/hooks';
export const presign = (albumId, file, token, cancelToken) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.post(`/albums/${albumId}/upload-url`, {
        fileName: file.name,
    }, {
        cancelToken,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
});
const addPhoto = (albumId, data, token, cancelToken) => __awaiter(void 0, void 0, void 0, function* () {
    return yield axios
        .post(`/albums/${albumId}/photos`, data, {
        cancelToken: cancelToken,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => {
        return res.data.createdId;
    });
});
export const useFilePondUploader = (albumId) => {
    const token = useAccessToken();
    const processImage = useCallback((photoId, cancelToken) => __awaiter(void 0, void 0, void 0, function* () {
        yield axios.post(`/photos/${photoId}/process`, null, {
            headers: { Authorization: `Bearer ${token}` },
            cancelToken,
        });
        return photoId;
    }), [token]);
    return useCallback((fieldName, file, metadata, load, error, progress, abort) => {
        const cancelTokenSource = axios.CancelToken.source();
        const putFile = ({ url, key }) => __awaiter(void 0, void 0, void 0, function* () {
            yield axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
                cancelToken: cancelTokenSource.token,
                onUploadProgress: (ev) => {
                    progress(true, ev.loaded, ev.total);
                },
            });
            return key;
        });
        presign(albumId, file, token, cancelTokenSource.token)
            .then(putFile)
            .then((key) => addPhoto(albumId, {
            fileLocation: key,
            fileName: file.name,
            fileSize: file.size,
        }, token, cancelTokenSource.token))
            .then((x) => processImage(x, cancelTokenSource.token))
            .then((id) => {
            load(id);
        })
            .catch((e) => {
            error(e.message || 'Error uploading file');
        });
        return {
            abort: () => {
                cancelTokenSource.cancel();
                abort();
            },
        };
    }, [albumId, processImage, token]);
};
