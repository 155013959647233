import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
export const InputImage = ({ control, name, ratio }) => {
    const { field: { value, onChange }, } = useController({
        name,
        control,
    });
    const [preview, setPreview] = useState(value);
    const [, setSelectedFile] = useState();
    const onSelectFile = useCallback((ev) => {
        const file = ev.target.files.item(0);
        if (!file) {
            return;
        }
        ev.target.value = '';
        setSelectedFile(file);
        setPreview((x) => {
            if (x) {
                URL.revokeObjectURL(x);
            }
            const url = URL.createObjectURL(file);
            return url;
        });
        if (onChange) {
            onChange(file);
        }
    }, [onChange]);
    const remove = useCallback(() => {
        setPreview(null);
        setSelectedFile(null);
        if (onChange) {
            onChange(null);
        }
    }, [onChange]);
    return (_jsxs(StyledContainer, Object.assign({ ratio: ratio }, { children: [_jsx(ImagePreview, Object.assign({ url: preview }, { children: _jsx("div", { className: "img" }, void 0) }), void 0), _jsx("div", { className: "btn-overlay" }, void 0), _jsxs("div", Object.assign({ className: "btns" }, { children: [_jsx("label", Object.assign({ className: "btn", onClick: remove }, { children: _jsx("i", { className: "fa fa-trash" }, void 0) }), void 0), _jsxs("label", Object.assign({ className: "btn btn-edit" }, { children: [_jsx("i", { className: "fa fa-pencil-alt" }, void 0), _jsx("input", { type: "file", accept: "image/*", onInput: onSelectFile }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
const StyledContainer = styled.div `
  position: relative;
  border: 1px solid var(--gray-2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  overflow: hidden;

  &::before {
    position: relative;
    display: block;
    content: '';
    padding-top: ${(p) => 100 / p.ratio}%;
  }

  .btn-overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0006;
    opacity: 0;
    z-index: 0;
    transition: all 0.15s linear;
  }
  &:hover {
    .btn-overlay {
      opacity: 1;
    }

    .btn {
      opacity: 1;
    }
  }

  .btn {
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    text-align: center;
    line-height: 2em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff8;
    transition: all 0.15s linear;
    opacity: 0;

    & + .btn {
      margin-left: 0.5em;
    }

    &:hover {
      background-color: var(--gray-2);
      color: #fff8;
    }

    input {
      position: absolute;
      margin-left: -10000px;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;
const ImagePreview = styled.div `
  background-image: url(${(p) => p.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
`;
export default InputImage;
