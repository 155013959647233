var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '~src/libs/http';
import decode from 'jwt-decode';
export const login = createAsyncThunk('auth/login', (cred) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const response = yield http.post('/auth/publisher', cred);
        const user = decode(response.data.accessToken);
        return {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email,
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            exp: user.exp,
        };
    }
    catch (e) {
        if (e.isAxiosError) {
            throw (_a = e.response.data) === null || _a === void 0 ? void 0 : _a.error;
        }
        throw e;
    }
}));
export const refreshToken = createAsyncThunk('auth/refershToken', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        const state = thunkApi.getState();
        const response = yield http.post('/auth/publisher/session', {
            sessionHint: state.application.currentUser.accessToken,
            refreshToken: state.application.currentUser.refreshToken,
        });
        const user = decode(response.data.accessToken);
        return {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email,
            accessToken: response.data.accessToken,
            exp: user.exp,
        };
    }
    catch (e) {
        if (e.isAxiosError) {
            throw (_b = e.response.data) === null || _b === void 0 ? void 0 : _b.error;
        }
        throw e;
    }
}));
export const logout = createAsyncThunk('auth/logout', () => __awaiter(void 0, void 0, void 0, function* () {
    // await firebase.auth().signOut();
    // TODO: implement logout
}));
