import { jsx as _jsx } from "preact/jsx-runtime";
import styled from 'styled-components';
const Loading = ({ height, width }) => {
    return _jsx(StyledLoading, { style: { width, height } }, void 0);
};
export default Loading;
const StyledLoading = styled.div `
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 10px;
`;
