import axios from 'axios';
import { useEffect, useState } from 'react';
import { useInterval } from '~src/hooks/useInterval';
import { useAccessToken } from '~src/state/application/hooks';
export const useTaskProgress = (albumId, id) => {
    const token = useAccessToken();
    const [data, setData] = useState();
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    useInterval(() => {
        setLastUpdate(Date.now());
    }, 5000);
    useEffect(() => {
        if (!id || !albumId) {
            return;
        }
        const cts = axios.CancelToken.source();
        axios
            .get(`/albums/${albumId}/extract`, {
            params: {
                taskId: id,
            },
            headers: {
                authorization: `Bearer ${token}`,
            },
            cancelToken: cts.token,
        })
            .then((res) => res.data)
            .then(setData);
        return () => {
            cts.cancel();
        };
    }, [albumId, id, lastUpdate]);
    return data;
};
