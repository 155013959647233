import { combineReducers } from 'redux';
import * as currentUser from './currentUser';
import { loginPage } from './loginPage';
import { notifications } from './notifications';
import breadcrumbs, { initialState as breadcrumbsInitState } from './breadcrumbs';
const reducers = combineReducers({
    currentUser: currentUser.slice.reducer,
    loginPage: loginPage.reducer,
    popupList: notifications.reducer,
    breadcrumbs,
});
export const initialState = {
    currentUser: currentUser.initialState,
    popupList: [],
    breadcrumbs: breadcrumbsInitState,
};
export default reducers;
