import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export default function useQuery() {
    const search = useLocation().search;
    return useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);
}
export function useQueryParam(key, defaultValue) {
    const search = useLocation().search;
    return useMemo(() => {
        return new URLSearchParams(search).get(key) || defaultValue;
    }, [search, key, defaultValue]);
}
