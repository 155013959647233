import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import currentUser from './currentUser';
import { notifications } from './notifications';
export const useCurrentUser = () => {
    return useSelector((state) => state.application.currentUser);
};
export const useLoadCurrentUser = () => {
    const dispatch = useDispatch();
    return useCallback((user) => {
        dispatch(currentUser.loadUser(user));
    }, [dispatch]);
};
export function useActivePopups() {
    const list = useSelector((state) => state.application.popupList);
    return useMemo(() => list.filter((item) => item.show), [list]);
}
export function useRemovePopup() {
    const dispatch = useDispatch();
    return useCallback((key) => {
        dispatch(notifications.actions.removePopup(key));
    }, [dispatch]);
}
// returns a function that allows adding a popup
export function useAddPopup() {
    const dispatch = useDispatch();
    return useCallback((content, key, waiting) => {
        const removeAfterMs = content.type === 'waiting' || waiting ? null : 5e3;
        dispatch(notifications.actions.addPopup({ content, key, removeAfterMs }));
    }, [dispatch]);
}
export const useAccessToken = () => useSelector((s) => { var _a; return (_a = s.application.currentUser) === null || _a === void 0 ? void 0 : _a.accessToken; });
