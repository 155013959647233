import { Link } from 'react-router-dom';
import styled from 'styled-components';
const colors = {
    primary: '#fff',
    danger: '#fff',
    secondary: '#222',
    success: '#fff',
    info: '#fff',
};
const background = {
    primary: 'var(--blue)',
    danger: 'var(--red)',
    secondary: 'var(--gray)',
    success: 'var(--green)',
    info: 'var(--purple)',
};
const fontSize = {
    sm: '14px',
    md: '16px',
    lg: '18px',
};
const padding = {
    sm: '.25rem .5rem',
    md: '.375rem .75rem',
    lg: '0.5rem 1rem',
};
const borderRadius = {
    sm: '2px',
    md: '4px',
    lg: '6px',
};
export const Button = styled.button `
  padding: ${(p) => padding[p.size || 'md']};
  border-radius: ${(p) => borderRadius[p.size || 'md']};
  border: 1px solid ${(p) => background[p.variant || 'secondary']};
  background-color: ${(p) => (!p.outline ? background[p.variant || 'secondary'] : 'transparent')};
  color: ${(p) => (p.outline ? background[p.variant || 'secondary'] : colors[p.variant || 'secondary'])};
  font-size: ${(p) => fontSize[p.size || 'md']};
  display: ${(p) => (p.block ? 'block' : 'inline-block')};
  width: ${(p) => (p.block ? '100%' : undefined)};
  line-height: 1.5;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;

  &:hover {
    filter: ${(p) => (p.block ? undefined : 'brightness(0.9)')};
    background-color: ${(p) => (p.outline ? background[p.variant || 'secondary'] : undefined)};
    color: ${(p) => (p.outline ? colors[p.variant || 'secondary'] : undefined)};
  }

  &::before {
    content: ${(p) => (p.loading ? "'\f1ce'" : undefined)};
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-right: 10px;
    animation: fa-spin 2s linear infinite;
    vertical-align: middle;
    font-size: 0.8em;
  }
`;
export const LinkButton = styled(Link) `
  padding: ${(p) => padding[p.size || 'md']};
  border-radius: ${(p) => borderRadius[p.size || 'md']};
  border: 1px solid ${(p) => background[p.variant || 'secondary']};
  background-color: ${(p) => (!p.outline ? background[p.variant || 'secondary'] : 'transparent')};
  color: ${(p) => (p.outline ? background[p.variant || 'secondary'] : colors[p.variant || 'secondary'])};
  font-size: ${(p) => fontSize[p.size || 'md']};
  display: ${(p) => (p.block ? 'block' : 'inline-block')};
  width: ${(p) => (p.block ? '100%' : undefined)};
  line-height: 1.5;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;

  &:hover {
    filter: ${(p) => (p.block ? undefined : 'brightness(0.9)')};
    background-color: ${(p) => (p.outline ? background[p.variant || 'secondary'] : undefined)};
    color: ${(p) => (p.outline ? colors[p.variant || 'secondary'] : colors[p.variant || 'secondary'])};
  }

  &::before {
    content: ${(p) => (p.loading ? "'\f1ce'" : undefined)};
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-right: 10px;
    animation: fa-spin 2s linear infinite;
    vertical-align: middle;
    font-size: 0.8em;
  }
`;
