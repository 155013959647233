import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import styled from 'styled-components';
const PageHeader = ({ children, action }) => {
    return (_jsxs(StyledPageHeader, { children: [_jsx("div", { children: children }, void 0), _jsx("div", { children: action }, void 0)] }, void 0));
};
export default PageHeader;
const StyledPageHeader = styled.div `
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;

  h2 {
    margin: 0;
  }
`;
