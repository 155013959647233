var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '~src/config';
import { createNotify } from '~src/state/application/notifications';
console.debug('base url', config.apiBaseUrl);
axios.defaults.baseURL = config.apiBaseUrl;
// axios.interceptors.request.use((config) => {
//   if (config.url.match(/^https?:\/\//)) {
//     return config;
//   }
//   if (firebase.auth().currentUser) {
//     //TODO: add bearer
//     firebase
//       .auth()
//       .currentUser.getIdToken()
//       .then((token) => {
//         if (token) {
//           config.headers['Authorization'] = 'Bearer ' + token;
//         }
//       });
//   }
//   config.headers['Content-type'] = 'application/json';
//   return config;
// });
export const http = axios;
export const cancelToken = (signal) => {
    const cts = axios.CancelToken.source();
    signal.onabort = () => cts.cancel();
    return cts.token;
};
export const createAsyncThunkWithNotify = (typePrefix, payloadCreator, messages) => createAsyncThunk(typePrefix, (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const notify = createNotify(thunkAPI.requestId, messages);
    try {
        thunkAPI.dispatch(notify.loading);
        const res = yield payloadCreator(params, thunkAPI);
        thunkAPI.dispatch(notify.success);
        return res;
    }
    catch (e) {
        thunkAPI.dispatch(notify.error);
        throw e;
    }
}));
