import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React, { useRef } from 'react';
import { createContext, useCallback, useState } from 'react';
import styled from 'styled-components';
export const Context = createContext({
    content: null,
    isOpen: false,
    onPresent: () => {
        /*noop*/
    },
    onDismiss: () => {
        /*noop*/
    },
});
export const ModalsProvider = ({ children, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [backdropClick, setBackdropClick] = useState(true);
    const [content, setContent] = useState();
    const onHidden = useRef();
    const onPresent = useCallback((modalContent, bgClick, onHiddenCallback) => {
        setIsOpen(true);
        setBackdropClick(bgClick);
        setContent(modalContent);
        onHidden.current = onHiddenCallback;
    }, []);
    const onDismiss = useCallback(() => {
        setIsOpen(false);
        setContent(undefined);
        if (onHidden.current) {
            onHidden.current();
        }
    }, []);
    return (_jsxs(Context.Provider, Object.assign({ value: {
            content,
            isOpen,
            onPresent,
            onDismiss,
        } }, { children: [children, isOpen && (_jsxs(StyledModalWrapper, { children: [backdropClick ? (_jsx(StyledModalBackdrop, { onClick: onDismiss }, void 0)) : (_jsx(StyledModalBackdrop, {}, void 0)), React.isValidElement(content) &&
                        React.cloneElement(content, {
                            onDismiss,
                        })] }, void 0))] }), void 0));
};
const StyledModalWrapper = styled.div `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;
const StyledModalBackdrop = styled.div `
  background-color: #00000088;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  backdrop-filter: blur(5px);
`;
