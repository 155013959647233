import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import { BreadcrumbItem } from '~src/components/Breadcrumb';
import FormAssociateEvent from './FormAssociateEvent';
import FormEditAlbumInfo from './FormEditAlbumInfo';
import FormPublish from './FormPublish';
import SectionDeleteAlbum from './SectionDeleteAlbum';
import { Section, SectionTitle, SectionSubtitle } from './Shared';
const Settings = () => {
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbItem, { label: "Edit album" }, void 0), _jsxs(Section, { children: [_jsx(SectionTitle, { children: "Basic information" }, void 0), _jsx(FormEditAlbumInfo, {}, void 0)] }, void 0), _jsxs(Section, { children: [_jsx(SectionTitle, { children: "External event" }, void 0), _jsx(SectionSubtitle, { children: "Associate with external event, like race from RaceJungle v4 API" }, void 0), _jsx(FormAssociateEvent, {}, void 0)] }, void 0), _jsx(FormPublish, {}, void 0), _jsx(SectionDeleteAlbum, {}, void 0)] }, void 0));
};
export default Settings;
