var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useCallback } from 'react';
import styled from 'styled-components';
import { gql, useMutation } from 'urql';
import { useDownloadUrl } from '~src/api/useImageApi';
import { ContentEditable } from '~src/components/ContentEditable';
import { Button } from '~src/components/Layout';
import Confirmation from '~src/components/Layout/Comfirmation';
import { Modal, ModalCloseButton } from '~src/components/Modal';
import { useNotify } from '~src/hooks/useNotify';
import { screenUp } from '~src/utils/styles';
const MUTATION_PUBLISH_PHOTO = gql `
  mutation ($id: String!, $isPublished: Boolean!) {
    publishPhoto(id: $id, isPublished: $isPublished)
  }
`;
const MUTATION_SET_PHOTO_LABEL = gql `
  mutation ($id: String!, $label: String!) {
    setPhotoLabel(id: $id, label: $label)
  }
`;
const MUTATION_DELETE_PHOTO = gql `
  mutation ($id: String!) {
    deletePhoto(id: $id)
  }
`;
const MUTATION_PROCESS_PHOTO = gql `
  mutation ($id: String!) {
    processPhoto(id: $id)
  }
`;
const ModalViewPhoto = ({ photo, onDismiss, onChange }) => {
    const url = useDownloadUrl(photo);
    const [, publishPhoto] = useMutation(MUTATION_PUBLISH_PHOTO);
    const [, setLabel] = useMutation(MUTATION_SET_PHOTO_LABEL);
    const [, processPhoto] = useMutation(MUTATION_PROCESS_PHOTO);
    const [, deletePhoto] = useMutation(MUTATION_DELETE_PHOTO);
    const onSaveLabel = useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        yield setLabel({
            id: photo,
            label: value,
        });
    }), [photo, setLabel]);
    const onProcessPhoto = useCallback(() => {
        processPhoto({
            id: photo.id,
        });
    }, [photo, processPhoto]);
    const publishNotify = useNotify({
        loading: 'Changing publishing status...',
        error: 'Failed change publishding status',
        success: 'Publishing status changed',
    });
    const togglePublish = useCallback(() => {
        publishNotify.loading();
        publishPhoto({
            id: photo.id,
            isPublished: !photo.isPublished,
        })
            .then(() => {
            photo.isPublished = !photo.isPublished;
            onChange();
            publishNotify.success();
        })
            .catch(publishNotify.error);
    }, [publishPhoto, photo, onChange, publishNotify]);
    const deleteNotify = useNotify({
        loading: 'Deleting photo...',
        error: 'Failed to delete photo',
        success: 'Photo deleted',
    });
    const onDeletePhoto = useCallback(() => {
        deleteNotify.loading();
        deletePhoto({ id: photo.id })
            .then(() => {
            deleteNotify.success();
            onChange();
        })
            .catch(() => {
            deleteNotify.error();
        });
    }, [deleteNotify, deletePhoto, photo.id, onChange]);
    return (_jsxs(StyledModal, Object.assign({ size: "xl" }, { children: [_jsx(ModalCloseButton, { onClick: onDismiss }, void 0), _jsxs(ModalContent, { children: [_jsx(Image, { children: photo && url ? _jsx("img", { src: url, alt: photo.id }, void 0) : null }, void 0), _jsxs(Sidebar, { children: [_jsx("h4", { children: photo === null || photo === void 0 ? void 0 : photo.fileName }, void 0), _jsxs("div", Object.assign({ className: "text-content" }, { children: [_jsx("h5", { children: "Label" }, void 0), _jsx(ContentEditable, { value: photo.label, onSave: onSaveLabel }, void 0), _jsxs("div", Object.assign({ className: "actions" }, { children: [_jsx(Button, Object.assign({ variant: "primary", onClick: onProcessPhoto, disabled: !!photo.label }, { children: "Process" }), void 0), "\u00A0", _jsx(Button, Object.assign({ variant: (photo === null || photo === void 0 ? void 0 : photo.isPublished) ? 'danger' : 'success', outline: true, onClick: togglePublish }, { children: (photo === null || photo === void 0 ? void 0 : photo.isPublished) ? 'Unpublish' : 'Publish' }), void 0), _jsxs(DangerZone, { children: [_jsx("div", Object.assign({ className: "help-text" }, { children: "This action cannot be undone. Please be certain" }), void 0), _jsx(Confirmation, Object.assign({ content: `Are you sure you want to delete this photo? This action cannot be undone.`, btnOkLabel: "Yes. Delete it", onConfirmed: onDeletePhoto }, { children: _jsxs(Button, Object.assign({ variant: "danger", onClick: onDeletePhoto }, { children: [_jsx("i", { className: "fa fa-trash" }, void 0), " Delete"] }), void 0) }), void 0)] }, void 0)] }), void 0)] }), void 0)] }, void 0)] }, void 0)] }), void 0));
};
export default ModalViewPhoto;
const StyledModal = styled(Modal).attrs({ size: 'xl' }) `
  background-color: #000;
`;
const ModalContent = styled.div `
  ${screenUp('lg') `
    display: grid;
    grid-template-columns: 1fr 360px;
  `}
`;
const Image = styled.div `
  align-self: center;

  img {
    width: 100%;
    display: block;
  }
`;
const Sidebar = styled.div `
  padding: 15px;
  background-color: #fff;
`;
const DangerZone = styled.div `
  padding-top: 20px;
  margin-top: 20px;
  border-top: var(--gray-1);

  .help-text {
    color: var(--gray-1);
    font-size: 0.875em;
    padding-bottom: 20px;
  }
`;
