import { jsx as _jsx } from "preact/jsx-runtime";
import React, { createContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import useOutsideClick from '~src/hooks/useOutsideClick';
export const DropdownContext = createContext(null);
export const Dropdown = ({ children }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();
    useOutsideClick(ref, () => {
        setOpen(false);
    });
    const value = useMemo(() => {
        return {
            isOpen: open,
            toggle: () => {
                setOpen((x) => !x);
            },
        };
    }, [open]);
    return (_jsx(DropdownContext.Provider, Object.assign({ value: value }, { children: _jsx(StyledDropdownContainer, Object.assign({ ref: ref }, { children: children }), void 0) }), void 0));
};
const StyledDropdownContainer = styled.div `
  position: relative;
  display: inline-block;
`;
