import styled from 'styled-components';
export const Container = styled.div `
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;

  & & {
    padding-left: 0;
    padding-right: 0;
  }
`;
