var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { cancelToken } from '~src/libs/http';
export const fetchUserList = createAsyncThunk('users/fetchList', (params, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.get('/users', {
        params: {
            page: params === null || params === void 0 ? void 0 : params.page,
        },
        cancelToken: cancelToken(thunkApi.signal),
    });
    return res.data;
}));
export const fetchUser = createAsyncThunk('users/fetch', (id, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.get(`/users/${id}`, {
        cancelToken: cancelToken(thunkApi.signal),
    });
    return res.data;
}));
export const updateUser = createAction('users/update');
export const fetchUserEvents = createAsyncThunk('user/fetchEvent', (userId, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.get(`/users/${userId}/all-events`, {
        cancelToken: cancelToken(thunkApi.signal),
    });
    return Object.assign(Object.assign({}, res.data), { userId });
}));
