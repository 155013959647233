import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "preact/jsx-runtime";
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { BreadcrumbItem } from '~src/components/Breadcrumb';
import { Dropdown, DropdownMenu, DropdownMenuItem, DropdownToggle } from '~src/components/Dropdown';
import { Button, LinkButton } from '~src/components/Layout';
import Loading from '~src/components/Layout/Loading';
import PageHeader from '~src/components/Layout/PageHeader';
import { AlbumContext } from './AlbumContext';
import PhotosList from './PhotosList';
import Settings from './Settings';
import Upload from './Upload';
import UploadZip from './UploadZip';
export const QUERY_PUBLISHER_ALBUM = gql `
  query ($id: Int!) {
    publisherAlbum(id: $id) {
      id
      title
      slug
      coverImageUrl
      featuredImageUrl
      isPublished
      eventId
      raceDay
      location
      organizer
      event {
        id
        name
      }
    }
  }
`;
const AlbumDetail = () => {
    const { albumId } = useParams();
    const { url, path } = useRouteMatch();
    const [{ data, fetching }] = useQuery({
        query: QUERY_PUBLISHER_ALBUM,
        variables: {
            id: +albumId,
        },
    });
    const album = data === null || data === void 0 ? void 0 : data.publisherAlbum;
    console.log('album', album);
    if (fetching) {
        return _jsx(Loading, { height: "180px" }, void 0);
    }
    const actions = (_jsxs(_Fragment, { children: [_jsxs(LinkButton, Object.assign({ variant: "primary", outline: true, to: `${url}/upload-zip` }, { children: [_jsx("i", { className: "fa fa-file-archive" }, void 0), " Upload zip"] }), void 0), ' ', _jsxs(LinkButton, Object.assign({ to: `${url}/upload`, outline: true, variant: "success" }, { children: [_jsx("i", { className: "fa fa-file-upload" }, void 0), " Upload"] }), void 0), ' ', _jsxs(Dropdown, { children: [_jsx(DropdownToggle, { children: _jsx(Button, Object.assign({ outline: true, variant: "info" }, { children: _jsx("i", { className: "fa fa-ellipsis-v" }, void 0) }), void 0) }, void 0), _jsxs(DropdownMenu, Object.assign({ position: "right" }, { children: [_jsx(DropdownMenuItem, { children: _jsxs(Link, Object.assign({ to: `${url}/search` }, { children: [_jsx("i", { className: "fa fa-search" }, void 0), " Search"] }), void 0) }, void 0), _jsx(DropdownMenuItem, { children: _jsxs(Link, Object.assign({ to: `${url}/settings` }, { children: [_jsx("i", { className: "fa fa-wrench" }, void 0), " Edit"] }), void 0) }, void 0)] }), void 0)] }, void 0)] }, void 0));
    return (_jsxs(AlbumContext.Provider, Object.assign({ value: album }, { children: [_jsx(BreadcrumbItem, { path: `/albums`, label: "Albums" }, void 0), _jsx(BreadcrumbItem, { path: `/albums/${albumId}`, label: album === null || album === void 0 ? void 0 : album.title }, void 0), _jsx(PageHeader, Object.assign({ action: actions }, { children: _jsx("h2", { children: album === null || album === void 0 ? void 0 : album.title }, void 0) }), void 0), _jsxs(Switch, { children: [_jsx(Route, Object.assign({ path: `${path}/`, exact: true }, { children: _jsx(PhotosList, {}, void 0) }), void 0), _jsx(Route, Object.assign({ path: `${path}/upload` }, { children: _jsx(Upload, {}, void 0) }), void 0), _jsx(Route, Object.assign({ path: `${path}/upload-zip` }, { children: _jsx(UploadZip, {}, void 0) }), void 0), _jsx(Route, Object.assign({ path: `${path}/settings` }, { children: _jsx(Settings, {}, void 0) }), void 0)] }, void 0)] }), void 0));
};
export default AlbumDetail;
