import { createSlice, nanoid } from '@reduxjs/toolkit';
export const notifications = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        addPopup: (state, { payload: { key, removeAfterMs, content }, }) => {
            return (key ? state.filter((popup) => popup.key !== key) : state).concat([
                {
                    key: key || nanoid(),
                    show: true,
                    content,
                    removeAfterMs,
                },
            ]);
        },
        removePopup: (state, { payload }) => {
            return state.filter((popup) => popup.key !== payload);
        },
    },
});
export const createNotify = (key, message) => {
    const loading = notifications.actions.addPopup({
        key,
        content: {
            type: 'waiting',
            message: message.loading,
        },
    });
    const success = notifications.actions.addPopup({
        key,
        content: {
            type: 'success',
            message: message.success,
        },
        removeAfterMs: 5000,
    });
    const error = notifications.actions.addPopup({
        key,
        content: {
            type: 'error',
            message: message.error,
        },
        removeAfterMs: 5000,
    });
    return {
        loading,
        success,
        error,
    };
};
