import { nanoid } from '@reduxjs/toolkit';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BreadcrumbActions } from '~src/state/application/breadcrumbs';
export const BreadcrumbItem = ({ path, label, icon }) => {
    const id = useRef(nanoid());
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(BreadcrumbActions.set({
            id: id.current,
            label,
            icon,
            path,
        }));
    }, [dispatch, icon, label, path]);
    useEffect(() => {
        const idc = id.current;
        return () => {
            dispatch(BreadcrumbActions.remove(idc));
        };
    }, [dispatch]);
    return null;
};
