import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { FilePond } from 'react-filepond';
import { BreadcrumbItem } from '~src/components/Breadcrumb';
import { Button } from '~src/components/Layout';
import { useAlbum } from '../AlbumContext';
import { useTaskProgress } from './useTaskProgress';
import { useUploadZip } from './useUploadZip';
const UploadZip = () => {
    const album = useAlbum();
    const process = useUploadZip(album === null || album === void 0 ? void 0 : album.id);
    const [taskId, setTaskId] = useState();
    const [fileName, setFileName] = useState();
    const onProcess = useCallback((_error, task) => {
        setTaskId(task.serverId);
        setFileName(task.filename);
    }, []);
    const taskProgress = useTaskProgress(album === null || album === void 0 ? void 0 : album.id, taskId);
    const output = useMemo(() => {
        return Object.entries((taskProgress === null || taskProgress === void 0 ? void 0 : taskProgress.output) || {}).map(([k, v]) => {
            return Object.assign(Object.assign({}, v), { path: k });
        });
    }, [taskProgress]);
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbItem, { label: "Upload zip file" }, void 0), _jsx("h3", { children: "Upload zip" }, void 0), _jsx("p", Object.assign({ className: "text-muted" }, { children: "Upload zip file contain multiple photos. File will be extracted and processed as a single file" }), void 0), !taskProgress ? (_jsx(FilePond, { acceptedFileTypes: ['application/zip'], onprocessfile: onProcess, server: { process }, allowMultiple: false }, void 0)) : (_jsxs(_Fragment, { children: [(taskProgress === null || taskProgress === void 0 ? void 0 : taskProgress.status) == 'processing' ? (_jsxs(_Fragment, { children: ["Processing file ", fileName] }, void 0)) : (taskProgress === null || taskProgress === void 0 ? void 0 : taskProgress.status) === 'error' ? (_jsxs(_Fragment, { children: [" Processing file ", fileName, " failed"] }, void 0)) : (_jsxs(_Fragment, { children: [" File ", fileName, " extracted complete"] }, void 0)), _jsx("p", { children: "Output:" }, void 0), _jsx("ul", { children: output.map((t, id) => (_jsxs("li", { children: [t.path, ": ", t.success, " ", t.error] }, id))) }, void 0), _jsxs("p", { children: [_jsx(Button, Object.assign({ variant: "primary", outline: true, size: "sm" }, { children: "Upload another file" }), void 0), _jsx("div", { children: _jsx("small", Object.assign({ className: "text-muted" }, { children: "Current extracting job will be processed in background" }), void 0) }, void 0)] }, void 0)] }, void 0))] }, void 0));
};
export default UploadZip;
