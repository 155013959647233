var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelToken, createAsyncThunkWithNotify, http } from '~src/libs/http';
export const fetchEvents = createAsyncThunk('events/fetch', ({ page, quantity }, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const skip = (page - 1) * quantity;
    const res = yield http.get('/events', {
        params: {
            skip,
            take: quantity,
        },
        cancelToken: cancelToken(thunkApi.signal),
    });
    return res.data;
}));
export const fetchSingleEvent = createAsyncThunk('events/fetchSingle', (id, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .get(`/events/${id}`, {
        cancelToken: cancelToken(thunkApi.signal),
    })
        .then((res) => res.data);
}));
export const deleteSingleEvent = createAsyncThunkWithNotify('events/deleteSingle', (id, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    return http
        .delete(`/events/${id}`, {
        cancelToken: cancelToken(thunkApi.signal),
    })
        .then(() => id);
}), {
    loading: 'Deleting event...',
    success: 'Event deleted successfully',
    error: 'Event delete failed. Please try again shortly',
});
