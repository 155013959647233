import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
export const ContentEditable = ({ value: initValue, onSave }) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(initValue);
    const ref = useRef();
    const [saving, setSaving] = useState(false);
    const submit = useCallback(() => {
        if (saving) {
            return;
        }
        setSaving(true);
        onSave(ref.current.value)
            .then(() => {
            setValue(ref.current.value);
            setEditing(false);
            setSaving(false);
        })
            .catch(() => {
            setSaving(false);
        });
    }, [onSave, saving]);
    return (_jsxs(Container, { children: [editing ? (_jsxs(_Fragment, { children: [_jsx(Textarea, { defaultValue: value, ref: ref }, void 0), _jsx(Buttons, { children: _jsx(ButtonSave, Object.assign({ onClick: submit, disabled: saving }, { children: _jsx("i", { className: `fa ${saving ? 'fa-spiner fa-spin' : 'fa-check'}` }, void 0) }), void 0) }, void 0)] }, void 0)) : null, _jsx("div", Object.assign({ className: "content", onClick: () => setEditing(true) }, { children: value }), void 0)] }, void 0));
};
const Textarea = styled.textarea `
  border: 1px solid var(--gray);
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  resize: none;
  font-family: inherit;
`;
const Buttons = styled.div `
  position: absolute;
  right: 5px;
  bottom: 5px;
`;
const Container = styled.div `
  position: relative;
  min-height: 40px;
  .content {
    font-size: 0.875em;
  }
`;
const ButtonSave = styled.button `
  width: 28px;
  height: 28px;
  line-height: 26px;
  text-align: center;
  color: var(--green);
  border: var(--green);
  background-color: #fff;
  border-radius: 50%;
`;
