var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNotify } from '~src/hooks/useNotify';
import { http } from '~src/libs/http';
import { gql, useQuery } from 'urql';
const s3Url = (key) => `https://ddm-media.s3-ap-southeast-1.amazonaws.com/${key.replace(/\s/g, '+')}`;
export const useImageUploader = () => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    return useCallback((file) => __awaiter(void 0, void 0, void 0, function* () {
        if (!file) {
            return '';
        }
        if (typeof file === 'string') {
            return file;
        }
        const { url, key } = yield http
            .post('/images/upload-url', {
            fileName: file.name,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data);
        yield http.put(url, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        return s3Url(key);
    }), [token]);
};
export const useDownloadUrl = (photo) => {
    var _a;
    const [{ data }] = useQuery({
        query: gql `
      query ($id: String!) {
        photo(id: $id) {
          downloadUrl
        }
      }
    `,
        variables: {
            id: photo.id,
        },
    });
    return (_a = data === null || data === void 0 ? void 0 : data.photo) === null || _a === void 0 ? void 0 : _a.downloadUrl;
};
export const useProcessPhoto = (photo) => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (photo) {
            yield http.post(`/photos/${photo.id}/process`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
    }), [photo, token]);
};
export const useIndexFace = (photo) => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    const notify = useNotify({
        loading: 'Indexing photo...',
        success: 'Photo indexed',
        error: 'Photo index failed',
    });
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (photo) {
            notify.loading();
            try {
                yield http.post(`/photos/${photo.id}/index-face`, null, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                notify.success();
            }
            catch (e) {
                notify.error();
            }
        }
    }), [photo, notify, token]);
};
export const useDownloadPhotos = () => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    return useCallback((photoIds) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield http.request({
            method: 'POST',
            url: `/photos/zip`,
            responseType: 'blob',
            data: {
                photoIds,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const a = document.createElement('a');
        a.download = 'photos.zip';
        a.href = URL.createObjectURL(res.data);
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            URL.revokeObjectURL(a.href);
        });
    }), [token]);
};
export const useUpdatePhoto = () => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    return useCallback((photoId, data) => {
        return http.patch(`/photos/${photoId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, [token]);
};
export const useDeletePhoto = () => {
    const token = useSelector((s) => s.application.currentUser.accessToken);
    return useCallback((photoId) => {
        return http.delete(`/photos/${photoId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, []);
};
// export const useDownloadUrl = (x: any) => x
