import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
export const WaitingPopup = ({ message, title }) => {
    return (_jsxs(Container, { children: [_jsx(StyledIcon, { children: _jsx("i", { className: "fa fa-circle-notch fa-spin" }, void 0) }, void 0), _jsxs("div", { children: [title ? _jsx(Title, { children: title }, void 0) : null, _jsx("span", { children: message }, void 0)] }, void 0)] }, void 0));
};
const Container = styled.div `
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background: var(--blue);
  }
`;
const Title = styled.div `
  font-weight: 600;
`;
const StyledIcon = styled.div `
  margin-right: 15px;
  flex-shrink: 0;
  font-size: 1.2rem;
`;
