import { jsx as _jsx } from "preact/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { useActivePopups } from '../../state/application/hooks';
import { screenUp } from '../../utils/styles';
import { PopupItem } from './PopupItem';
export const Popups = () => {
    const popupItems = useActivePopups();
    return (_jsx(PopupWrapper, { children: popupItems.map((t) => (_jsx(PopupItem, { popupId: t.key, removeAfterMs: t.removeAfterMs, content: t.content }, t.key))) }, void 0));
};
const PopupWrapper = styled.div `
  position: fixed;
  z-index: 1010;
  top: 0;
  width: 100%;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;

  ${screenUp('md') `
    top: 30px;
    right: 30px;
    max-width: 320px;
    transform: none;
    left: unset;
    bottom: unset;
  `}
`;
