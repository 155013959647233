import styled from 'styled-components';
export const Input = styled.input `
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--gray-2);
  display: block;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:focus {
    border-color: var(--blue-1);
    box-shadow: 0 1px 5px var(--blue-1);
    outline: none;
  }
`;
export const Select = styled.select `
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--gray-2);
  display: block;
  width: 100%;
  transition: all 0.2s ease-in-out;
  background-color: transparent;

  &:focus {
    border-color: var(--blue-1);
    box-shadow: 0 1px 5px var(--blue-1);
    outline: none;
  }
`;
export const FormLabel = styled.label `
  display: block;
  font-size: 0.875rem;
  padding-bottom: 0.5em;
`;
export const FormHint = styled.div `
  font-size: 0.8rem;
  color: var(--gray);
  margin-top: 0.5em;
`;
export const FormFeedback = styled(FormHint) ``;
export const FormError = styled(FormHint) `
  color: var(--red);
`;
export const FormGroup = styled.div `
  padding-bottom: 20px;

  ${FormLabel} {
    color: ${(p) => (p.danger ? 'var(--red)' : undefined)};
  }

  ${FormError} {
    display: ${(p) => (p.danger ? 'block' : 'none')};
  }
`;
