var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createReducer } from '@reduxjs/toolkit';
import axios from 'axios';
import { createAsyncThunkWithNotify } from '~src/libs/http';
const initialState = {};
export const publishAlbum = createAsyncThunkWithNotify('albums/publish', (albumId) => __awaiter(void 0, void 0, void 0, function* () {
    yield axios.post(`/albums/${albumId}/publish`);
    return {
        albumId,
    };
}), {
    loading: 'Toggling publish',
    error: 'Publish album failed',
    success: 'Album published',
});
const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(publishAlbum.pending, (state) => {
        state.publishing = true;
    })
        .addCase(publishAlbum.fulfilled, (state) => {
        state.publishing = false;
    })
        .addCase(publishAlbum.rejected, (state) => {
        state.publishing = false;
    });
});
export default reducer;
export const selectAlbumSettings = (x) => x.ui.albumSettings;
