import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { LinkButton } from '~src/components/Layout';
const UsersList = ({ users }) => {
    const { url } = useRouteMatch();
    return (_jsx(StyledSection, { children: _jsxs(StyledTable, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "User" }, void 0), _jsx("th", { children: "Email" }, void 0), _jsx("th", {}, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: users.map((t) => (_jsxs("tr", { children: [_jsxs("td", { children: [_jsx(Username, { children: t.name || '-- name not set --' }, void 0), _jsx("div", Object.assign({ className: "text-muted" }, { children: _jsxs("small", { children: [_jsx("i", { className: "fa fa-user" }, void 0), " ", t.id] }, void 0) }), void 0)] }, void 0), _jsx("td", { children: t.email }, void 0), _jsx("td", { children: _jsx(Actions, { children: _jsxs(LinkButton, Object.assign({ variant: "primary", size: "sm", to: `${url}/${t.id}` }, { children: [_jsx("i", { className: "fa fa-eye" }, void 0), "\u00A0View"] }), void 0) }, void 0) }, void 0)] }, void 0))) }, void 0)] }, void 0) }, void 0));
};
export default UsersList;
const StyledSection = styled.div `
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
`;
const StyledTable = styled.table `
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      text-align: left;
      text-transform: uppercase;
      color: var(--gray);
      padding: 5px 10px;
      font-size: 0.8em;
      border-bottom: 1px solid var(--gray-1);
      letter-spacing: 2px;
    }
  }

  tbody {
    td {
      padding: 10px 10px;
      border-bottom: 1px solid var(--gray-2);
    }
  }
`;
const Username = styled.div `
  font-weight: bold;
  margin-bottom: 5px;
`;
const Actions = styled.div `
  display: flex;
  justify-content: flex-end;
`;
