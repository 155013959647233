import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchUser, fetchUserList, updateUser } from './actions';
const userAdapter = createEntityAdapter();
const userSlice = createSlice({
    name: 'users',
    initialState: userAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserList.fulfilled, (state, { payload }) => {
            userAdapter.setAll(state, payload.items);
        });
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            userAdapter.setOne(state, payload);
        });
        builder.addCase(updateUser, (state, action) => {
            userAdapter.updateOne(state, {
                changes: action.payload,
                id: action.payload.id,
            });
        });
    },
});
export default userSlice.reducer;
export const userSelectors = userAdapter.getSelectors((s) => s.users.users);
