import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import styled from 'styled-components';
const Empty = ({ icon, children }) => {
    return (_jsxs(StyledEmpty, { children: [_jsx("i", { className: icon }, void 0), _jsx("div", { children: children }, void 0)] }, void 0));
};
export default Empty;
const StyledEmpty = styled.div `
  background-color: var(--gray-2);
  border: 1px solid var(--gray-1);
  color: var(--gray);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  i {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;
