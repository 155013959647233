import { jsx as _jsx } from "preact/jsx-runtime";
import styled from 'styled-components';
const ModalSize = {
    xl: 1200,
    lg: 800,
    sm: 300,
    md: 500,
};
export const Modal = styled.div `
  max-width: ${(p) => ModalSize[p.size || 'md']}px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  overflow: hidden;
`;
export const ModalHeader = styled.div `
  padding: 20px;
  position: relative;
  background-color: var(--purple);
  color: #fff;
`;
export const ModalTitle = styled.h3 `
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  position: relative;
`;
export const ModalCloseButton = styled.button.attrs({
    children: _jsx("i", { className: "fa fa-times" }, void 0),
}) `
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1;
`;
export const ModalContent = styled.div `
  padding: 20px 20px 20px 20px;
`;
export const ModalFooter = styled.div `
  padding: 0px 20px 20px 20px;
  text-align: center;
`;
