import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
export const SuccessPopup = ({ title, message }) => {
    return (_jsxs(Container, { children: [_jsx(StyledIcon, { children: _jsx("i", { className: "fa fa-check-circle" }, void 0) }, void 0), _jsxs("div", { children: [_jsx(Title, { children: title }, void 0), _jsx("div", { children: message }, void 0)] }, void 0)] }, void 0));
};
const Container = styled.div `
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: var(--green);
  }
`;
const Title = styled.div `
  font-weight: 600;
`;
const StyledIcon = styled.div `
  margin-right: 10px;
  flex-shrink: 0;
  color: var(--green);
`;
