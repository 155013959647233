import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import application, { initialState as appInitialState } from './application/reducer';
import { albumSlice } from './albums/reducer';
import users from './users/reducers';
import events from './events/reducers';
import photos, { initialState as photosInitialState } from './photos/reducers';
import publishers from './publishers/reducers';
import ui from './ui';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { save, load } from 'redux-localstorage-simple';
import { createClient } from '@urql/core';
import { config } from '~src/config';
const PersistentState = ['application.currentUser'];
const PersistentNamespace = '_rj_media_';
export const history = createBrowserHistory();
const rootReducer = combineReducers({
    application,
    albums: albumSlice.reducer,
    users,
    events,
    photos,
    ui,
    router: connectRouter(history),
    publishers,
});
let client;
const getClient = () => {
    if (!client) {
        client = createClient({
            url: config.apiBaseUrl + '/api/graphql',
        });
    }
    return client;
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    getClient,
                },
            },
        }),
        routerMiddleware(history),
        save({ states: PersistentState, namespace: PersistentNamespace }),
    ],
    preloadedState: load({
        states: PersistentState,
        namespace: PersistentNamespace,
        preloadedState: {
            application: Object.assign({}, appInitialState),
            photos: Object.assign({}, photosInitialState),
        },
    }),
});
