var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { gql } from '@urql/core';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from 'urql';
import { Button } from '~src/components/Layout';
import { useNotify } from '~src/hooks/useNotify';
import { selectAlbumSettings } from '~src/state/ui/albumSettings';
import { useAlbum } from '../AlbumContext';
import { Section, SectionSubtitle, SectionTitle } from './Shared';
export const MUTATION_PUBLISH_ALBUM = gql `
  mutation ($id: Int!, $isPublished: Boolean!) {
    publishAlbum(id: $id, isPublished: $isPublished)
  }
`;
const FormPublish = () => {
    const album = useAlbum();
    const { publishing } = useSelector(selectAlbumSettings);
    const [publishResult, publish] = useMutation(MUTATION_PUBLISH_ALBUM);
    const notify = useNotify({
        loading: 'Toggling publish',
        error: 'Publish album failed',
        success: 'Album published',
    });
    const toggle = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        notify.loading();
        const res = yield publish({
            id: album.id,
            isPublished: !album.isPublished,
        });
        if (res.error) {
            notify.error();
        }
        else if (res.data) {
            notify.success();
        }
    }), [album, notify, publish]);
    return (_jsxs(Section, { children: [_jsx(SectionTitle, { children: "Publish" }, void 0), _jsx(SectionSubtitle, { children: "Once publish visitor can view and search for photos of them. Otherwise this album will be hidden." }, void 0), _jsxs(Button, Object.assign({ variant: "primary", onClick: toggle, loading: publishing }, { children: [_jsx("i", { className: `fa ${(album === null || album === void 0 ? void 0 : album.isPublished) ? 'fa-globe' : 'fa-lock-alt'}` }, void 0), "\u00A0", (album === null || album === void 0 ? void 0 : album.isPublished) ? 'Unpublish' : 'Publish'] }), void 0)] }, void 0));
};
export default FormPublish;
