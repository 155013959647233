// import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useAddPopup } from '~src/state/application/hooks';
export const useNotify = (message = {}) => {
    const id = useRef('' + Date.now());
    const addPopup = useAddPopup();
    const loading = useCallback((mess) => {
        addPopup({
            type: 'waiting',
            message: mess || message.loading,
        }, id.current);
    }, [addPopup, message.loading]);
    const success = useCallback((mess) => {
        addPopup({
            type: 'success',
            message: mess || message.success,
        }, id.current);
    }, [message.success, addPopup]);
    const error = useCallback((mess) => {
        addPopup({
            message: mess || message.error,
            type: 'error',
        }, id.current);
    }, [message.error, addPopup]);
    return {
        loading,
        success,
        error,
    };
};
