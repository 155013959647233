import { createReducer } from '@reduxjs/toolkit';
import { fetchEvents } from '../events/actions';
const initialState = {
    loading: true,
};
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchEvents.pending, (state, action) => {
        state.page = action.meta.arg.page;
        state.quantity = action.meta.arg.quantity;
        state.loading = true;
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
        state.page = action.meta.arg.page;
        state.quantity = action.meta.arg.quantity;
        state.loading = false;
        state.total = action.payload.count;
    });
    builder.addCase(fetchEvents.rejected, (state) => {
        state.loading = false;
    });
});
export default reducer;
