import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { FilePond } from 'react-filepond';
import { BreadcrumbItem } from '~src/components/Breadcrumb';
import { useAlbum } from '../AlbumContext';
import { useFilePondUploader } from './useFilePond';
const Upload = () => {
    const album = useAlbum();
    const process = useFilePondUploader(album === null || album === void 0 ? void 0 : album.id);
    return (_jsxs(_Fragment, { children: [_jsx(BreadcrumbItem, { label: "Upload files" }, void 0), _jsx("h3", { children: "Upload" }, void 0), _jsx(FilePond, { acceptedFileTypes: ['image/*'], server: { process }, allowMultiple: true }, void 0)] }, void 0));
};
export default Upload;
