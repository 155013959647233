import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { ImageContainer } from '~src/components/Layout';
import { useModal } from '~src/components/Modal';
import { thumbnailUrl } from '~src/utils/url';
import ModalViewPhoto from '../ModalViewPhoto';
const PhotoCard = ({ photo, onChange }) => {
    const thumbnail = useMemo(() => {
        return thumbnailUrl(photo.fileLocation, 256);
    }, [photo.fileLocation]);
    const [view] = useModal(_jsx(ModalViewPhoto, { onChange: onChange, photo: photo }, void 0));
    return (_jsxs(StyledPhotoCard, Object.assign({ src: thumbnail, onClick: view }, { children: [_jsx(PhotoCardContent, { children: photo.fileName }, void 0), !photo.isPublished ? _jsx(Icon, { className: "fa fa-lock" }, void 0) : null] }), void 0));
};
export default PhotoCard;
const PhotoCardContent = styled.div `
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #000a;
  color: #fff;
  transform: translateY(100%);
  transition: transform linear 0.15s;
  font-size: 0.8em;
`;
const StyledPhotoCard = styled(ImageContainer).attrs({ ratio: 1 }) `
  overflow: hidden;
  border-radius: 4px;
  transition: all 0.2s linear;
  background-color: #0002;

  &:hover {
    box-shadow: 0 1px 5px #0005, 0 2px 10px #0005;
    ${PhotoCardContent} {
      transform: translateY(0);
    }
  }
`;
const Icon = styled.i `
  font-size: 1.2em;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.8;
  color: var(--red);
`;
