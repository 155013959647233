import { Fragment as _Fragment, jsx as _jsx } from "preact/jsx-runtime";
import { gql } from '@urql/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'urql';
import decode from 'jwt-decode';
import { refreshToken as refreshTokenAction } from '~src/state/application/actions';
export const MUTATION_REFRESH_TOKEN = gql `
  mutation refreshToken($refreshToken: String!, $sessionHint: String!) {
    refreshPublisherAccountToken(refreshToken: $refreshToken, sessionHint: $sessionHint) {
      accessToken
    }
  }
`;
const Updater = () => {
    const user = useSelector((s) => s.application.currentUser);
    const [, refresh] = useMutation(MUTATION_REFRESH_TOKEN);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!(user === null || user === void 0 ? void 0 : user.exp)) {
            return;
        }
        let timeout = user.exp * 1000 - Date.now() - 60e3;
        timeout = timeout > 0 ? timeout : 0;
        console.log('Schedule refresh token in ', timeout / 1000, 'seconds');
        const it = setTimeout(() => {
            refresh({ refreshToken: user.refreshToken, sessionHint: user.accessToken }).then((response) => {
                const data = response.data.refreshPublisherAccountToken;
                console.log(data);
                const user = decode(data.accessToken);
                dispatch(refreshTokenAction.fulfilled({
                    uid: user.uid,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    email: user.email,
                    accessToken: data.accessToken,
                    exp: user.exp,
                }, ''));
            });
        }, timeout);
        return () => {
            clearTimeout(it);
        };
    }, [user, refresh, dispatch]);
    return _jsx(_Fragment, {}, void 0);
};
export default Updater;
