import styled from 'styled-components';
export const Section = styled.section `
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid var(--gray-1);
  background-color: #fff;
  border-radius: 8px;
`;
export const SectionTitle = styled.h3 `
  position: relative;
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-1);
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
`;
export const SectionSubtitle = styled.div `
  color: #666;
  padding-bottom: 20px;
`;
