import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { gql } from '@urql/core';
import { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'urql';
import Empty from '~src/components/Layout/Empty';
import Loading from '~src/components/Layout/Loading';
import Pagination from '~src/components/Layout/Pagination';
import { useQueryParam } from '~src/hooks/useQuery';
import PhotoCard from './PhotoCard';
export const QUERY_ALBUM_PHOTOS = gql `
  query ($album: Int!, $skip: Int!, $take: Int!) {
    publisherAlbum(id: $album) {
      id
      photoCount
      photos(skip: $skip, take: $take) {
        edge {
          id
          fileName
          fileSize
          fileLocation
          isPublished
          label
        }
        count
      }
    }
  }
`;
export const QUANTITY = 50;
const PhotosList = () => {
    var _a, _b, _c;
    const page = useQueryParam('page', '1');
    const { albumId } = useParams();
    const [{ fetching, data }, refetch] = useQuery({
        query: QUERY_ALBUM_PHOTOS,
        variables: {
            skip: (+page - 1) * QUANTITY,
            take: QUANTITY,
            album: +albumId,
        },
        requestPolicy: 'cache-and-network',
    });
    const history = useHistory();
    const { url } = useRouteMatch();
    const gotoPage = useCallback((page) => {
        const search = new URLSearchParams();
        search.append('page', page.toString());
        history.push({
            pathname: url,
            search: search.toString(),
        });
    }, [history, url]);
    const photos = (_b = (_a = data === null || data === void 0 ? void 0 : data.publisherAlbum) === null || _a === void 0 ? void 0 : _a.photos) === null || _b === void 0 ? void 0 : _b.edge;
    const photoCount = (_c = data === null || data === void 0 ? void 0 : data.publisherAlbum) === null || _c === void 0 ? void 0 : _c.photoCount;
    console.log(data, fetching, photoCount);
    if (fetching) {
        return _jsx(Loading, { height: "180px" }, void 0);
    }
    if (!photoCount) {
        return _jsx(Empty, Object.assign({ icon: "fa fa-file-image" }, { children: "Album is empty" }), void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { children: photos === null || photos === void 0 ? void 0 : photos.map((t) => (_jsx(PhotoCard, { photo: t, onChange: refetch }, t.id))) }, void 0), _jsx(Pagination, { total: photoCount, page: +page, quantity: QUANTITY, onChangePage: gotoPage }, void 0)] }, void 0));
};
export default PhotosList;
const Grid = styled.div `
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  margin-bottom: 20px;
`;
