var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelToken, http } from '~src/libs/http';
export const fetchAlbumPhoto = createAsyncThunk('photos/fetch', (params, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    const take = params.quantity || 12;
    const skip = (params.page - 1) * take;
    const res = yield http.get(`/albums/${params.albumId}/photos`, {
        params: {
            skip,
            take,
        },
        cancelToken: cancelToken(thunkApi.signal),
    });
    return {
        items: res.data.data,
        count: res.data.metadata.count,
        albumId: params.albumId,
    };
}));
export const updatePhoto = createAsyncThunk('photo/updateLabel', (data, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    yield http.patch(`/api/photos/${data.id}`, data, {
        cancelToken: cancelToken(thunkApi.signal),
    });
    return data;
}));
