import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { range } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
const MaxPageToShow = 5;
const Pagination = ({ total, quantity, page, onChangePage }) => {
    const totalPages = useMemo(() => {
        return Math.ceil(total / quantity);
    }, [total, quantity]);
    const pages = useMemo(() => {
        if (totalPages <= MaxPageToShow) {
            return range(1, totalPages + 1);
        }
        let from = Math.max(1, Math.ceil(page - MaxPageToShow / 2));
        let to = Math.min(from + MaxPageToShow, totalPages);
        if (to - from < MaxPageToShow) {
            from = to - MaxPageToShow;
        }
        return range(from, to);
    }, [page, totalPages]);
    const next = useCallback(() => {
        if (page === totalPages) {
            return;
        }
        onChangePage(page + 1);
    }, [onChangePage, page, totalPages]);
    const prev = useCallback(() => {
        if (page === 1) {
            return;
        }
        onChangePage(page - 1);
    }, [onChangePage, page]);
    return (_jsxs(StyledList, { children: [_jsx(StyledLi, Object.assign({ onClick: prev }, { children: _jsx("i", { className: "fa fa-arrow-left" }, void 0) }), void 0), pages.map((t) => (_jsx(StyledLi, Object.assign({ current: t === page, "data-page": t, onClick: () => onChangePage(t) }, { children: t }), t))), _jsx(StyledLi, Object.assign({ onClick: next }, { children: _jsx("i", { className: "fa fa-arrow-right" }, void 0) }), void 0)] }, void 0));
};
const StyledList = styled.ul `
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
`;
const StyledLi = styled.li `
  padding: 10px;
  cursor: pointer;
  color: ${(p) => (p.current ? 'var(--blue)' : undefined)};
  font-weight: ${(p) => (p.current ? 'bold' : 'normal')};
`;
export default Pagination;
