import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useRemovePopup } from '~src/state/application/hooks';
import { screenUp } from '../../utils/styles';
import { ErrorPopup } from './ErrorPopup';
import { SuccessPopup } from './SuccessPopup';
import { WaitingPopup } from './WaitingPopup';
export const PopupItem = ({ removeAfterMs, popupId, content }) => {
    const removePopup = useRemovePopup();
    const removeThisPopup = useCallback(() => removePopup(popupId), [popupId, removePopup]);
    useEffect(() => {
        if (removeAfterMs == null)
            return undefined;
        const timeout = setTimeout(() => {
            removeThisPopup();
        }, removeAfterMs);
        return () => {
            clearTimeout(timeout);
        };
    }, [removeAfterMs, removeThisPopup]);
    let contentDiv = null;
    switch (content.type) {
        case 'waiting':
            contentDiv = _jsx(WaitingPopup, Object.assign({}, content), void 0);
            break;
        case 'error':
            contentDiv = _jsx(ErrorPopup, Object.assign({}, content), void 0);
            break;
        case 'success':
            contentDiv = _jsx(SuccessPopup, Object.assign({}, content), void 0);
            break;
    }
    return (_jsxs(StyledPopup, { children: [_jsx(StyledClose, { onClick: removeThisPopup }, void 0), contentDiv] }, void 0));
};
const StyledPopup = styled.div `
  background-color: #222;
  color: #fff;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  animation-name: slideInRight;
  animation-duration: 0.3s;
  animation-delay: 0s;
  ${screenUp('md') `
    padding: 15px;
  `}
`;
const StyledClose = styled.button.attrs({
    children: _jsx("i", { className: "fa fa-times" }, void 0),
}) `
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  opacity: 0.4;
  color: #ffffff;
  font-size: 20px;
  background-color: transparent;
  border: none;
  ${screenUp('md') `
    font-size: 16px;
  `}
`;
