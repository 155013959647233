var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "preact/jsx-runtime";
import React, { useContext } from 'react';
import styled from 'styled-components';
import { DropdownContext } from './Dropdown';
export const DropdownMenu = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const { isOpen, toggle } = useContext(DropdownContext);
    return (_jsx(StyledDropdownMenu, Object.assign({ isOpen: isOpen }, props, { onClick: toggle }, { children: children }), void 0));
};
const StyledDropdownMenu = styled.div `
  position: absolute;
  ${(p) => (p.direction === 'up' ? 'bottom: 100%' : 'top: 100%')};
  ${(p) => (p.position === 'right' ? 'right: 0' : 'left: 0')};
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 4px;
  ${(p) => (p.direction === 'up' ? 'margin-bottom: 5px;' : 'margin-top: 5px;')}
  z-index: 100;
  min-width: 200px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  opacity: ${(p) => (p.isOpen ? 1 : 0)};
  transform: scaleY(${(p) => (p.isOpen ? '100%' : '0%')});
  transition: all 0.2s linear;
  transform-origin: top;
`;
export const DropdownMenuItem = styled.div `
  border-radius: 4px;
  &:hover {
    background-color: var(--gray-2);
  }

  a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
  }

  button {
    display: block;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
  }
`;
