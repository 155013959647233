import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FormGroup, FormLabel, Button, Input, FormError } from '~src/components/Layout';
import { Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalTitle, } from '~src/components/Modal';
import { useNotify } from '~src/hooks/useNotify';
export const ModalAddNewUser = ({ onDismiss }) => {
    var _a, _b, _c, _d, _e, _f;
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const notify = useNotify({
        loading: 'Saving event...',
        success: 'Event saved',
        error: 'Error saving event. Please try again',
    });
    const onSubmit = handleSubmit((data) => {
        notify.loading();
        axios
            .post(`/api/users/register`, {
            email: data.email,
            fullname: data.fullname,
            password: data.password,
        })
            .then(() => {
            notify.success();
            onDismiss();
        })
            .catch((e) => {
            notify.error(e.message);
        });
    });
    return (_jsx(Modal, { children: _jsxs("form", Object.assign({ onSubmit: onSubmit }, { children: [_jsxs(ModalHeader, { children: [_jsx(ModalTitle, { children: "Create new user" }, void 0), _jsx(ModalCloseButton, { onClick: onDismiss }, void 0)] }, void 0), _jsxs(ModalContent, { children: [_jsxs(FormGroup, Object.assign({ danger: !!((_a = errors.email) === null || _a === void 0 ? void 0 : _a.message) }, { children: [_jsx(FormLabel, { children: "Email" }, void 0), _jsx(Input, Object.assign({ type: "text" }, register('email', { required: 'Email cannot be empty' })), void 0), _jsx(FormError, { children: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message }, void 0)] }), void 0), _jsxs(FormGroup, Object.assign({ danger: !!((_c = errors.fullname) === null || _c === void 0 ? void 0 : _c.message) }, { children: [_jsx(FormLabel, { children: "Fullname" }, void 0), _jsx(Input, Object.assign({ type: "fullname" }, register('fullname', { required: 'Full name cannot be empty' })), void 0), _jsx(FormError, { children: (_d = errors.fullname) === null || _d === void 0 ? void 0 : _d.message }, void 0)] }), void 0), _jsxs(FormGroup, Object.assign({ danger: !!((_e = errors.password) === null || _e === void 0 ? void 0 : _e.message) }, { children: [_jsx(FormLabel, { children: "Password" }, void 0), _jsx(Input, Object.assign({ type: "password" }, register('password', { required: 'Password cannot be empty' })), void 0), _jsx(FormError, { children: (_f = errors.password) === null || _f === void 0 ? void 0 : _f.message }, void 0)] }), void 0)] }, void 0), _jsx(ModalFooter, { children: _jsx(Button, Object.assign({ color: "primary", type: "submit" }, { children: "Save" }), void 0) }, void 0)] }), void 0) }, void 0));
};
