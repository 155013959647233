import { jsx as _jsx } from "preact/jsx-runtime";
import { createClient, dedupExchange } from '@urql/core';
import { cacheExchange } from '@urql/exchange-graphcache';
import { useSelector } from 'react-redux';
import { fetchExchange, Provider } from 'urql';
import { config } from '~src/config';
import { QUERY_PUBLISHER_ALBUM } from '~src/views/AlbumDetail';
import { QUERY_USER_LIST } from '~src/views/Users';
const cache = cacheExchange({
    updates: {
        Mutation: {
            users: ({ user }, _args, cache) => {
                const variables = { take: 10, skip: 0 }; // update if we are in first page
                cache.updateQuery({ query: QUERY_USER_LIST, variables }, (data) => {
                    if (data !== null) {
                        data.users.unshift(user);
                        data.feed.count++;
                        return data;
                    }
                    else {
                        return null;
                    }
                });
            },
            publishAlbum(root, args, cache) {
                cache.updateQuery({
                    query: QUERY_PUBLISHER_ALBUM,
                    variables: { id: args.id },
                }, (data) => {
                    if (data) {
                        data.publisherAlbum.isPublished = args.isPublished;
                    }
                    return data;
                });
            },
            login(root, args, cache) {
                cache.invalidate('User');
                cache.invalidate('UserInfo');
                cache.invalidate('Album');
                cache.invalidate('Photo');
                cache.invalidate('Photo');
                cache.invalidate('Publisher');
            },
        },
    },
});
const GrapqlProvider = ({ children }) => {
    const token = useSelector((s) => { var _a; return (_a = s.application.currentUser) === null || _a === void 0 ? void 0 : _a.accessToken; });
    const client = createClient({
        url: config.apiBaseUrl + '/graphql',
        exchanges: [dedupExchange, cache, fetchExchange],
        fetchOptions: () => {
            return {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            };
        },
    });
    return _jsx(Provider, Object.assign({ value: client }, { children: children }), void 0);
};
export default GrapqlProvider;
