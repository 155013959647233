import { useEffect, useRef } from 'react';
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });
    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (typeof (savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current) !== 'undefined') {
                savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current();
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
