var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { push } from 'connected-react-router';
import { omit } from 'lodash-es';
import { cancelToken, createAsyncThunkWithNotify } from '~src/libs/http';
import { createNotify } from '../application/notifications';
export const load = createAsyncThunk('albums/load', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const take = params.quantity || 10;
    const skip = (params.page - 1 || 0) * take;
    const res = yield axios.request({
        url: '/albums',
        params: {
            skip,
            take,
        },
        cancelToken: cancelToken(thunkAPI.signal),
    });
    return res.data;
}));
export const loadSingleAlbum = createAsyncThunk('albums/loadSingle', (albumId, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.request({
        url: `/albums/${albumId}`,
        cancelToken: cancelToken(thunkAPI.signal),
    });
    return res.data;
}));
export const createAlbum = createAsyncThunk('albums/create', (data, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const notify = createNotify(thunkAPI.requestId, {
        loading: 'Creating album',
        success: 'Album created successfully',
        error: 'Create album failed. Please try again later',
    });
    try {
        thunkAPI.dispatch(notify.loading);
        const res = yield axios.post(`/albums`, data, {
            cancelToken: cancelToken(thunkAPI.signal),
        });
        thunkAPI.dispatch(notify.success);
        thunkAPI.dispatch(push(`/albums/${res.data.createdId}`));
        return res.data;
    }
    catch (e) {
        thunkAPI.dispatch(notify.error);
        throw e;
    }
}));
export const updateAlbum = createAsyncThunkWithNotify('albums/update', (data, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield axios.patch(`/albums/${data.id}`, omit(data, ['id']), {
        cancelToken: cancelToken(thunkAPI.signal),
    });
    return res.data;
}), {
    loading: 'Updating album',
    success: 'Album updated successfully',
    error: 'Update album failed. Please try again later',
});
export const deleteAlbum = createAsyncThunk('albums/delete', (id, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const notify = createNotify(thunkAPI.requestId, {
        loading: 'Deleting album',
        success: 'Album deleted successfully',
        error: 'Delete album failed. Please try again later',
    });
    try {
        thunkAPI.dispatch(notify.loading);
        yield axios.delete(`/albums/${id}`, {
            cancelToken: cancelToken(thunkAPI.signal),
        });
        thunkAPI.dispatch(notify.success);
        thunkAPI.dispatch(push(`/albums`));
        return id;
    }
    catch (e) {
        thunkAPI.dispatch(notify.error);
        throw e;
    }
}));
export const associateEvent = createAsyncThunkWithNotify('event/associateEvent', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    yield axios.post(`/albums/${params.albumId}/associate`, {
        eventId: params.eventId,
    }, {
        cancelToken: cancelToken(thunkAPI.signal),
    });
    return params;
}), {
    loading: 'Associating event',
    success: 'Album is associated',
    error: 'Failed to assiciate event. Please try again shortly',
});
export const disassociateEvent = createAsyncThunkWithNotify('event/associateEvent', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    yield axios.delete(`/albums/${params.albumId}/associate`, {
        cancelToken: cancelToken(thunkAPI.signal),
    });
}), {
    loading: 'Associating event',
    success: 'Album is associated',
    error: 'Failed to assiciate event. Please try again shortly',
});
