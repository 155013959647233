var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { cloneElement } from 'preact';
import React, { useCallback } from 'react';
import { Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalTitle, useModal, } from '../Modal';
import { Button } from './Button';
export const ModalConfirmation = ({ onConfirmed, title, content, onDismiss, btnCancelLabel, btnOkLabel, variant, }) => {
    const onOk = useCallback(() => {
        onDismiss();
        onConfirmed();
    }, [onConfirmed, onDismiss]);
    return (_jsxs(Modal, { children: [_jsxs(ModalHeader, { children: [_jsx(ModalTitle, { children: title || 'Confirmation' }, void 0), _jsx(ModalCloseButton, { onClick: onDismiss }, void 0)] }, void 0), _jsx(ModalContent, { children: content }, void 0), _jsxs(ModalFooter, { children: [_jsx(Button, Object.assign({ variant: "secondary", outline: true, onClick: onDismiss }, { children: btnCancelLabel || 'Cancel' }), void 0), ' ', _jsx(Button, Object.assign({ variant: variant || 'primary', onClick: onOk }, { children: btnOkLabel || 'OK' }), void 0)] }, void 0)] }, void 0));
};
const Confirmation = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const [showModal] = useModal(_jsx(ModalConfirmation, Object.assign({}, props), void 0));
    return cloneElement(children, {
        onClick: showModal,
    });
};
export default Confirmation;
