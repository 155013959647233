import { createSlice } from '@reduxjs/toolkit';
import { login, logout, refreshToken } from './actions';
export const initialState = null;
export const slice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        // load from storage
        loadUser: (state, { payload: user }) => user,
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, { payload }) => {
            return payload;
        })
            .addCase(refreshToken.fulfilled, (state, { payload }) => {
            return Object.assign(Object.assign({}, state), payload);
        })
            .addCase(logout.fulfilled, () => initialState);
    },
});
export default slice.actions;
