import { combineReducers } from '@reduxjs/toolkit';
import albumSettings from './albumSettings';
import albumPhotos from './albumPhotos';
import eventList from './eventList';
const reducer = combineReducers({
    albumSettings,
    albumPhotos,
    eventList,
});
export default reducer;
