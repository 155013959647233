var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { useCallback } from 'react';
import { useAccessToken } from '~src/state/application/hooks';
import { presign } from '../Upload/useFilePond';
const startExtract = (albumId, fileLocation, token, cancelToken) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const res = yield axios.post(`/albums/${albumId}/extract`, {
        key: fileLocation,
    }, {
        cancelToken,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return (_a = res.data) === null || _a === void 0 ? void 0 : _a.taskId;
});
export const useUploadZip = (albumId) => {
    const token = useAccessToken();
    const process = useCallback((fieldName, file, metadata, load, error, progress, abort) => {
        const cancelTokenSource = axios.CancelToken.source();
        const putFile = ({ url, key }) => __awaiter(void 0, void 0, void 0, function* () {
            yield axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
                cancelToken: cancelTokenSource.token,
                onUploadProgress: (ev) => {
                    progress(true, ev.loaded, ev.total);
                },
            });
            return key;
        });
        presign(albumId, file, token, cancelTokenSource.token)
            .then(putFile)
            .then((key) => startExtract(albumId, key, token, cancelTokenSource.token))
            .then((id) => {
            load(id);
        })
            .catch((e) => {
            error(e.message || 'Error uploading file');
        });
        return {
            abort: () => {
                cancelTokenSource.cancel();
                abort();
            },
        };
    }, [albumId, token]);
    return process;
};
