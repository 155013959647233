import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { omit } from 'lodash-es';
import { fetchAlbumPhoto, updatePhoto } from './actions';
const adapter = createEntityAdapter();
export const initialState = adapter.getInitialState();
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchAlbumPhoto.fulfilled, (state, { payload }) => {
        return adapter.setAll(state, payload.items);
    });
    builder.addCase(updatePhoto.fulfilled, (state, { payload }) => {
        return adapter.updateOne(state, {
            id: payload.id,
            changes: omit(payload, 'id'),
        });
    });
});
export default reducer;
export const PhotosSelectors = adapter.getSelectors((s) => s.photos);
