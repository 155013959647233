import { jsxs as _jsxs, jsx as _jsx } from "preact/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { gql, useMutation, useQuery } from 'urql';
import { FormGroup, FormLabel, Button, Select } from '~src/components/Layout';
import { disassociateEvent } from '~src/state/albums/actions';
import { useAlbum } from '../AlbumContext';
const query = gql `
  query list_event($skip: Int!, $take: Int!) {
    events(skip: $skip, take: $take) {
      id
      name
      externalId
      createdAt
      eventProvider {
        id
        name
      }
    }
  }
`;
const update = gql `
  mutation ($albumId: Int!, $eventId: String) {
    associateAlbumEvent(albumId: $albumId, eventId: $eventId)
  }
`;
const FormAssociateEvent = () => {
    var _a;
    const album = useAlbum();
    const [{ data }] = useQuery({ query, variables: { skip: 0, take: 10 } });
    const options = (data === null || data === void 0 ? void 0 : data.events) || [];
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            eventId: album.eventId || null,
        },
    });
    const dispatch = useDispatch();
    useEffect(() => {
        console.log(album === null || album === void 0 ? void 0 : album.eventId);
        setValue('eventId', album === null || album === void 0 ? void 0 : album.eventId);
    }, [album, setValue]);
    const [, associateMutation] = useMutation(update);
    const associate = (eventId) => {
        associateMutation({
            albumId: album.id,
            eventId,
        });
    };
    const disassociate = useCallback(() => {
        dispatch(disassociateEvent({
            albumId: album.id,
        }));
    }, [album.id, dispatch]);
    const submit = handleSubmit((data) => {
        associate(data.eventId);
    });
    return (_jsxs("form", Object.assign({ onSubmit: submit }, { children: [album.event ? _jsxs("div", { children: ["Current event: ", (_a = album.event) === null || _a === void 0 ? void 0 : _a.name] }, void 0) : null, _jsxs(FormGroup, { children: [_jsx(FormLabel, { children: "Events" }, void 0), _jsxs(Select, Object.assign({}, register('eventId', { required: true }), { children: [_jsx("option", Object.assign({ value: '' }, { children: "-- Select one event --" }), void 0), options.map((item) => {
                                var _a;
                                return (_jsxs("option", Object.assign({ value: item.id }, { children: [item.name, " - (", ((_a = item.eventProvider) === null || _a === void 0 ? void 0 : _a.name) || 'unknown provider', ")"] }), item.id));
                            })] }), void 0)] }, void 0), _jsxs(FormGroup, Object.assign({ className: "text-right" }, { children: [_jsxs(Button, Object.assign({ variant: "primary", outline: true, type: "button", onClick: disassociate }, { children: [_jsx("i", { className: "fa fa-times" }, void 0), " Disassociate"] }), void 0), ' ', _jsxs(Button, Object.assign({ variant: "primary", type: "submit" }, { children: [_jsx("i", { className: "fa fa-check" }, void 0), " Associate"] }), void 0)] }), void 0)] }), void 0));
};
export default FormAssociateEvent;
