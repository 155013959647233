import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import Providers from './providers/Providers';
import Updater from './state/application/updater';
const Login = loadable(() => import('~src/views/Login'));
const MainWrapper = loadable(() => import('~src/views/MainWrapper'));
export const App = () => {
    return (_jsxs(Providers, { children: [_jsx(Updater, {}, void 0), _jsxs(Switch, { children: [_jsx(Route, Object.assign({ path: "/auth/login" }, { children: _jsx(Login, {}, void 0) }), void 0), _jsx(Route, Object.assign({ path: "/" }, { children: _jsx(MainWrapper, {}, void 0) }), void 0)] }, void 0)] }, void 0));
};
