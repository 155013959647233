import { createReducer, createSelector } from '@reduxjs/toolkit';
import { fetchAlbumPhoto } from '../photos/actions';
import { PhotosSelectors } from '../photos/reducers';
const initialState = {
    photoIds: [],
    photoCount: 0,
    fetching: true,
};
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchAlbumPhoto.fulfilled, (state, { payload }) => {
        state.photoCount = +payload.count || 0;
        state.photoIds = payload.items.map((t) => t.id);
        state.fetching = false;
    });
    builder.addCase(fetchAlbumPhoto.pending, (state, { meta }) => {
        state.fetching = true;
        state.page = meta.arg.page;
        state.quantity = meta.arg.quantity;
    });
    builder.addCase(fetchAlbumPhoto.rejected, (state) => {
        state.fetching = false;
    });
});
export default reducer;
export const selectAlbumPhotos = (x) => x.ui.albumPhotos;
export const selectAlbumPhotoIds = (x) => x.ui.albumPhotos.photoIds;
export const selectAllPhotos = createSelector([selectAlbumPhotoIds, PhotosSelectors.selectEntities], (ids, photos) => {
    return (ids === null || ids === void 0 ? void 0 : ids.map((i) => photos[i])) || [];
});
