import { createSlice } from '@reduxjs/toolkit';
import { login } from './actions';
const initialState = {
    loading: false,
    errorMessage: undefined,
};
export const loginPage = createSlice({
    name: 'loginPage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, () => {
            return {
                loading: true,
                errorMessage: undefined,
            };
        })
            .addCase(login.rejected, (_, action) => {
            return {
                loading: false,
                errorMessage: action.error.message,
            };
        })
            .addCase(login.fulfilled, () => initialState);
    },
});
