import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
const adapter = createEntityAdapter();
export const initialState = adapter.getInitialState({
    entities: {
        root: { path: '/', label: '', icon: 'fa fa-home', id: 'root' },
    },
    ids: ['root'],
});
const breadcrumbs = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {
        set: (state, action) => {
            return adapter.setOne(state, action.payload);
        },
        remove: (state, action) => {
            return adapter.removeOne(state, action.payload);
        },
    },
});
export default breadcrumbs.reducer;
export const BreadcrumbActions = breadcrumbs.actions;
export const BreadcrumbSelectors = adapter.getSelectors((s) => s.application.breadcrumbs);
