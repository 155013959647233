var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { gql } from '@urql/core';
export const fetchPublishers = createAsyncThunk('publishers/fetch', (params, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const client = thunkAPI.extra.getClient();
    console.log(state, client);
    const query = gql `
    query {
      publishers(skip: 0, take: 10) {
        id
        name
        logoUrl
      }
    }
  `;
    const a = yield client.query(query).toPromise();
    console.log(a);
    return a.data;
}));
