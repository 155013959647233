import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BreadcrumbSelectors } from '~src/state/application/breadcrumbs';
import { Link } from 'react-router-dom';
export const Breadcrumb = () => {
    const items = useSelector(BreadcrumbSelectors.selectAll);
    const isHome = useMemo(() => items.length === 1, [items.length]);
    if (isHome) {
        return (_jsx(Ul, { children: _jsx("li", { children: _jsxs(Link, Object.assign({ to: items[0].path }, { children: [_jsx("i", { className: items[0].icon }, void 0), " Home"] }), void 0) }, void 0) }, void 0));
    }
    return (_jsx(Ul, { children: items.map((t) => (_jsx("li", { children: _jsxs(Link, Object.assign({ to: t.path }, { children: [t.icon ? _jsx("i", { className: t.icon }, void 0) : null, t.label] }), void 0) }, t.id))) }, void 0));
};
const Ul = styled.ul `
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  margin-top: 20px;

  li {
    display: inline-block;
    color: var(--blue-2);
  }

  li + li {
    &::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f054';
      font-weight: 900;
      font-size: 12px;
      display: inline-block;
      padding: 0 5px;
      vertical-align: middle;
      color: var(--gray-1);
    }
  }
`;
