import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { deleteSingleEvent, fetchEvents, fetchSingleEvent } from './actions';
const adapter = createEntityAdapter();
export const initialState = adapter.getInitialState();
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
        adapter.setAll(state, action.payload.items);
    });
    builder.addCase(fetchSingleEvent.fulfilled, (state, { payload }) => {
        adapter.setOne(state, payload);
    });
    builder.addCase(deleteSingleEvent.fulfilled, (state, { payload }) => {
        adapter.removeOne(state, payload);
    });
});
export default reducer;
export const EventSelectors = adapter.getSelectors((s) => s.events);
