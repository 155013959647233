import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { fetchUserEvents } from './actions';
const adapter = createEntityAdapter({
    selectId: (t) => t.eventId,
});
const initialState = adapter.getInitialState({
    users: {},
});
const slice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserEvents.fulfilled, (state, action) => {
            const userId = action.payload.userId;
            const itemIds = action.payload.items.map((t) => t.eventId);
            return adapter.setAll(Object.assign(Object.assign({}, state), { users: {
                    [userId]: itemIds,
                } }), action.payload.items);
        });
    },
});
export default slice.reducer;
export const UserEventSelect = adapter.getSelectors((s) => s.users.events);
export const selectUserEvents = (userId) => createSelector([(s) => s.users.events.entities, (s) => s.users.events.users[userId]], (events, eventIds) => {
    console.debug(eventIds, userId);
    return (eventIds === null || eventIds === void 0 ? void 0 : eventIds.map((id) => events[id])) || [];
});
