import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "preact/jsx-runtime";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '~src/components/Layout';
import Confirmation from '~src/components/Layout/Comfirmation';
import { deleteAlbum } from '~src/state/albums/actions';
import { useAlbum } from '../AlbumContext';
import { Section, SectionTitle, SectionSubtitle } from './Shared';
const SectionDeleteAlbum = () => {
    const dispatch = useDispatch();
    const album = useAlbum();
    const onDelete = useCallback(() => {
        dispatch(deleteAlbum(album === null || album === void 0 ? void 0 : album.id));
    }, [album === null || album === void 0 ? void 0 : album.id, dispatch]);
    return (_jsxs(Section, { children: [_jsx(SectionTitle, { children: "Deletion" }, void 0), _jsx(SectionSubtitle, { children: "This action cannot be undone. Please be certain" }, void 0), _jsx("div", { children: _jsx(Confirmation, Object.assign({ title: "Deleting album", variant: "danger", onConfirmed: onDelete, content: _jsxs(_Fragment, { children: ["Are you sure you want to delete album ", _jsx("strong", { children: album === null || album === void 0 ? void 0 : album.title }, void 0), "? All album photos will be deleted as well. This action cannot be undone in any condition."] }, void 0), btnOkLabel: "I understand the risk. Please delete" }, { children: _jsxs(Button, Object.assign({ variant: "danger" }, { children: [_jsx("i", { className: "fa fa-trash" }, void 0), " Delete"] }), void 0) }), void 0) }, void 0)] }, void 0));
};
export default SectionDeleteAlbum;
