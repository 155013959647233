import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { fetchPublishers } from './actions';
const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(fetchPublishers.fulfilled, (state, { payload }) => {
        adapter.setAll(state, payload);
    });
});
export default reducer;
