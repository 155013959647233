import React, { useMemo } from 'react';
import { useCallback, useContext } from 'react';
import { Context } from './ModalsProvider';
export const useModal = (modal, backdropClick = true) => {
    const { onDismiss, onPresent } = useContext(Context);
    const handlePresent = useCallback(() => {
        // modal
        onPresent(modal, backdropClick);
    }, [modal, onPresent, backdropClick]);
    return [handlePresent, onDismiss];
};
export const useModalFactory = (componentFactory, backdropClick = true, onHidden) => {
    const { onDismiss, onPresent } = useContext(Context);
    const handlePresent = useCallback((props) => {
        const el = React.createElement(componentFactory, props);
        onPresent(el, backdropClick, onHidden);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onPresent, componentFactory, onHidden]);
    return useMemo(() => {
        return { open: handlePresent, dismiss: onDismiss };
    }, [handlePresent, onDismiss]);
};
export * from './components';
export * from './ModalsProvider';
