import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { fetchUserList } from './actions';
import users from './users';
import events from './events';
const userListPage = createSlice({
    name: 'userListPage',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(fetchUserList.rejected, (state) => {
            state.loading = false;
        });
    },
});
export default combineReducers({
    users: users,
    userListPage: userListPage.reducer,
    events,
});
